/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React, { useMemo } from 'react';
// ui
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ButtonWithLoader from 'commons/containers/ButtonWithLoader';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// container
import TimePicker from 'commons/components/TimePicker';
import DatePicker from 'commons/components/DatePicker';
import ConfirmDialog from 'commons/components/ConfirmDialog';

// utils
import { truthty } from 'utils/functions';
import WeightField from 'commons/containers/WeightField';
import { parseToDate } from 'utils/DateFormat';

const LotForm = (props) => {
  const {
    controls, changeControls, invalidControls, warehouses, fruits, varieties, products, packagingTypes, changeWeight, totalWeight,
    toggleForm, submit, isOpen, entryLots, process,
  } = props;

  const aviableFruitIds = entryLots.map((lot) => lot.fruitId);
  const fruitOptions = Object.values(fruits).filter((fruit) => aviableFruitIds.includes(fruit.id));
  const varietyOptions = Object.values(varieties).filter((variety) => variety.fruitId === controls.fruitId);

  const productsOptions = useMemo(() => {
    return [
      '',
      ...Object.values(products)
               .sort((a, b) => a.name - b.name)
    ]
  }, [products]);

  const productsOptionsFiltered = controls.varietyId
  ? productsOptions.filter((product) => product.varietyId === controls.varietyId)
  : productsOptions.filter((product) => varietyOptions.find((variety) => product.varietyId === variety.id));

  const productsOptionsWithoutVariety = productsOptions.filter((product) => !product.varietyId);

  productsOptionsWithoutVariety.forEach((product) => productsOptionsFiltered.push(product))

  const datetimePicker = (
    <DatePicker
      inputId="datetime"
      label="Fecha de ingreso"
      fullWidth
      value={controls.datetime}
      onChange={changeControls}
      type="datetime-local"
      inputProps={{
        'data-module': 'departure',
        min: parseToDate(process.date).date,
      }}
    />
  );

  const warehouseSelect = (
    <FormControl fullWidth>
      <InputLabel id="warehouse-label">Bodega</InputLabel>
      <Select
        labelId="warehouse-label"
        fullWidth
        value={controls.warehouseId}
        onChange={changeControls}
        error={invalidControls.warehouseId}
      >
        <MenuItem id="warehouseId" value="" data-module="departure">Ninguna</MenuItem>
        {Object.values(warehouses).map((warehouse) => (
          <MenuItem
            key={`warehouse-option-${warehouse.id}`}
            id="warehouseId"
            value={warehouse.id}
            data-module="departure"
          >
            {warehouse.name}
          </MenuItem>
        ))}
      </Select>
      {truthty(invalidControls.warehouseId) && <FormHelperText error>Debe ingresar una bodega</FormHelperText>}
    </FormControl>
  );

  const fruitSelect = (
    <Autocomplete
      value={controls.fruitId && fruits[controls.fruitId]}
      id="autocomplete-fruit-process"
      options={fruitOptions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
      getOptionLabel={(fruit) => fruit && fruit.name}
      renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Especie" margin="normal" />}
      onChange={(event, newValue) => {
        const e = {
          autocomplete: true,
          input: 'fruitId',
          value: newValue ? newValue.id : '',
          module: 'departure',
        };
        changeControls(e);
      }}
    />
  );

  const varietySelect = (
    <Autocomplete
      value={controls.varietyId && varieties[controls.varietyId]}
      id="autocomplete-variety-process"
      options={varietyOptions.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
      getOptionLabel={(variety) => variety && variety.name}
      renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Variedad" margin="normal" />}
      disabled={!controls.fruitId}
      onChange={(event, newValue) => {
        const e = {
          autocomplete: true,
          input: 'varietyId',
          value: newValue ? newValue.id : '',
          module: 'departure',
        };
        changeControls(e);
      }}
    />
  );

  const productSelect = (
    <Autocomplete
      value={controls.productId && productsOptionsFiltered.find((product) => product.id === controls.productId)}
      id="autocomplete-product-process"
      options={productsOptionsFiltered}
      getOptionLabel={(product) => product && product.name}
      renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Producto" margin="normal" />}
      disabled={!controls.varietyId}
      onChange={(event, newValue) => {
        const e = {
          autocomplete: true,
          input: 'productId',
          value: newValue ? newValue.id : '',
          module: 'departure',
        };
        changeControls(e);
      }}
    />
  );

  const packagingSelect = (
    <>
      <Autocomplete
        value={controls.packagingTypeId && Object.values(packagingTypes).find((packagingType) => packagingType.id === controls.packagingTypeId)}
        id="autocomplete-packagingType-receipts"
        options={Object.values(packagingTypes).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))}
        getOptionLabel={(packagingType) => packagingType && packagingType.name}
        renderInput={(params) => <TextField style={{ margin: 0 }} {...params} label="Envase" margin="normal" />}
        onChange={(event, newValue) => {
          const e = {
            autocomplete: true,
            input: 'packagingTypeId',
            value: newValue ? newValue.id : '',
            module: 'departure',
          };
          changeControls(e);
        }}
      />
      {truthty(invalidControls.packagingTypeId) && <FormHelperText error>Debe ingresar un tipo de envase</FormHelperText>}
    </>
  );

  const weightInput = (
    <WeightField
      // @ts-ignore
      quantity={controls.quantity}
      changeWeight={changeWeight}
      changeControls={changeControls}
      error={invalidControls.quantity}
      inputProps={{ 'data-module': 'departure' }}
      helperText={invalidControls.quantity && 'Debe ingresar la cantidad de kilos'}
      totalWeight={totalWeight}
    />
  );

  const packagingQuantityInput = (
    <TextField
      value={controls.packagingQuantity}
      onChange={changeControls}
      fullWidth
      id="packagingQuantity"
      label="Cantidad de envases"
      inputProps={{ 'data-module': 'departure' }}
      error={invalidControls.packagingQuantity}
      helperText={invalidControls.packagingQuantity && 'Debe ingresar la cantidad de envases'}
    />
  );

  const labelQuantityInput = (
    <TextField
      value={controls.labelQuantity}
      onChange={changeControls}
      fullWidth
      id="labelQuantity"
      label="Etiquetas a imprimir"
      inputProps={{ 'data-module': 'departure' }}
      error={invalidControls.labelQuantity}
      helperText={invalidControls.labelQuantity && 'Debe ingresar la cantidad de etiquetas'}
    />
  );

  const dialogContent = (
    <DialogContent>
      <Grid container spacing={2}>
        {/* --- fullscreen-row --- */}
        <Grid item sm={6} xs={12}>
          {datetimePicker}
        </Grid>
        <Grid item sm={6} xs={12}>
          {warehouseSelect}
        </Grid>
        {/* --- fullscreen-row --- */}
        <Grid item sm={6} xs={12}>
          {fruitSelect}
        </Grid>
        <Grid item sm={6} xs={12}>
          {varietySelect}
        </Grid>
        {/* --- fullscreen-row --- */}
        <Grid item sm={6} xs={12}>
          {productSelect}
        </Grid>
        <Grid item sm={6} xs={12}>
          {packagingSelect}
        </Grid>
        {/* --- fullscreen-row --- */}
        <Grid item sm={6} xs={12}>
          {weightInput}
        </Grid>
        <Grid item sm={6} xs={12}>
          {packagingQuantityInput}
        </Grid>
        <Grid item sm={6} xs={12}>
          {labelQuantityInput}
        </Grid>
      </Grid>
    </DialogContent>
  );

  const disableAddButton = truthty(invalidControls.warehouseId)
                          || truthty(invalidControls.fruitId)
                          || truthty(invalidControls.varietyId)
                          || truthty(invalidControls.productId)
                          || truthty(invalidControls.packagingTypeId)
                          || truthty(invalidControls.quantity)
                          || truthty(invalidControls.packagingQuantity)
                          || totalWeight <= 0;

  const dialogActions = (
    <DialogActions>
      <Button onClick={toggleForm} data-module="departure" color="primary" variant="outlined">
        Cancelar
      </Button>
      <ButtonWithLoader
        // @ts-ignore
        onClick={submit}
        disabled={disableAddButton}
        autoFocus
        buttonId="add-departure-lot"
      >
        Agregar
      </ButtonWithLoader>
    </DialogActions>
  );

  return (
    <Dialog
      // @ts-ignore
      TransitionProps={{ 'data-module': 'departure' }}
      aria-labelledby="simple-dialog-title"
      fullWidth
      onClose={toggleForm}
      open={isOpen}
    >
      <DialogTitle>Agregar salida de Pak</DialogTitle>
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
};

export default LotForm;

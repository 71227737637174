/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';
// ui
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
// import Tooltip from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import AddIcon from '@material-ui/icons/LibraryAdd';
// components
import StyledTableCell from 'commons/components/StyledTableCell';
import { getLocalDate } from 'utils/DateFormat';
import DatePicker from 'commons/components/DatePicker';
import DeleteModal from 'commons/components/DeleteModal';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CircularProgress } from '@material-ui/core';
import { truthty, formatDate, falsy, roundNumber } from 'utils/functions';
import ConfirmDialog from 'commons/components/ConfirmDialog';
import IconButtonWithLoader from 'commons/containers/IconButtonWithLoader';
import DialButton from './DialButton';
import Entries from './Entries';
import Departures from './Departures';
import WorkShiftStaff from './WorkShiftStaff';
import SupplyProductLines from './SupplyProductLines';
import StaffForm from './StaffForm';
import LotForm from './LotForm';
import SupplyProductForm from './SupplyProductForm';
import EntryForm from './EntryForm';
import ProcessEquipmentForm from './ProcessEquipmentForm';
import ProcessEquipments from './ProcessEquipments';
import Headers from './Headers';

const ProcessComponent = (props) => {
  const {
    classes, lotId, process, multipleLotsProcess, uniqueLot, objects, toggleDelete, processStaffLines, supplyLines, printFnc, printFncPaks,
    formControls, invalidControls, formIsOpen, changeControls, submitStaff, toggleForm, employeeOptions, submitLot,
    changeWeight, totalWeight, submitEntry, qrReader, qrCode, onChangeFnc, submitSupplyProduct, role, submitDelete,
    speedDialToggle, dialButtonOpen, closeProcess, toggleClose, printAllFnc, processResources, equipmentOptions,
    submitProcessEquipments, isAdmin, infoLot, usedLot, actions, stopLoading, infoEntryLots,
    objects: {
      roles,
      lots,
      products,
      varieties,
      fruits,
      equipments,
    },
    onBackAction
  } = props;

  const infoMsg = {
    'open': '¿Está seguro que quiere abrir el proceso? Al hacer esto se podrían modificar las mermas, rendimientos y costos del proceso, se bloquearán los operadores para estar presentes en otros turnos y cambiará la fecha de cierre de proceso',
    'close': '¿Está seguro que quiere cerrar el proceso? Al cerrar el proceso se registrará la fecha de cierre del mismo, se permitirá a los empleados estar en otros turnos y añadir o retirar paks e insumos'
  }

  const warningMsg = () => {
    const entryWeight = Object.values(lots).filter((lot) => lot.destinyProcessId === process.id).reduce((acum, l) => acum + l.quantity, 0)
    const departureWeight = Object.values(lots).filter((lot) => lot.originProcessId === process.id).reduce((acum, l) => acum + l.quantity, 0)
    let msg = [];
    if(entryWeight <= 0) msg.push(`no posee paks en la entrada`);
    if(departureWeight <= 0) msg.push(`no posee paks en la salida`);
    if(entryWeight - departureWeight < 0) msg.push(`presenta una merma de ${entryWeight - departureWeight}`);
    if(msg.length > 0) msg.unshift(`Proceso Nº${process.id}`)

    return msg.length > 0 ? msg.join(', ') : null
  }

  const getProductsHeaders = (departureHeaders = false) => {
    // let headers = ['N° Pak', 'Especie', 'Variedad', 'Producto', 'N° Envases', 'Cantidad', 'Costo Pak', falsy(departureHeaders) && falsy(process.closedAt) && ' '];
    let headers = ['N° Pak', 'Especie', 'Variedad', 'Producto', 'N° envases', 'Cantidad', falsy(departureHeaders) && falsy(process.closedAt) && ' '];
    if (formControls.headers.isGroupedInfo) {
      headers = ['Cantidad', 'Especie', 'Variedad', 'Producto', 'Kilos totales'];
    }
    return headers.map((header) => <StyledTableCell align="right">{header}</StyledTableCell>);
  };

  const entryLots = Object.values(lots).filter((lot) => lot.destinyProcessId === process.id);
  const departureLots = Object.values(lots).filter((lot) => lot.originProcessId === process.id);
  const processEquipments = Object.values(objects.processEquipments).filter((pe) => pe.processId === process.id);
  const isSmallScreen = window.innerWidth < 600;

  const getProductsRows = (filteredLots = [], isDepartures) => {
    let rows = [];

    if (formControls.headers.isGroupedInfo) {
      rows = filteredLots.reduce((acum, lot) => {
        const newAcum = acum;
        const { quantity, fruitId, varietyId, productId, price } = lot;
        const index = acum.findIndex((elem) => fruitId === elem.fruitId && elem.varietyId === varietyId && productId === elem.productId);

        if (index === -1) {
          newAcum.push({
            quantity: 1,
            totalWeight: quantity,
            fruitId,
            varietyId,
            productId,
            totalPrice: price,
          });
        } else {
          const rowFunded = acum[index];
          newAcum[index] = { ...rowFunded, totalWeight: rowFunded.totalWeight + quantity, quantity: rowFunded.quantity + 1, totalPrice: rowFunded.price };
        }

        return acum;
      }, [])
        .map((row) => {
          const product = products[row.productId];
          const variety = varieties[row.varietyId];
          const fruit = fruits[row.fruitId];

          return (
            <>
              <TableRow key={`group-${fruit.id}-${variety.id}-${product.id}`}>
                <StyledTableCell align="right" component="th" scope="lot">
                  {row.quantity}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {fruit.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {variety.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {product.name}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {roundNumber(row.totalWeight)}
                  {' '}
                  [Kg]
                </StyledTableCell>
                {/* <StyledTableCell align="right">
                  {roundNumber(row.totalPrice)}
                  {' '}
                </StyledTableCell> */}
              </TableRow>
            </>
          );
        });
    } else {
      rows = filteredLots.map((lot) => (
        <TableRow key={`departure-lot-${lot.id}`}>
          <StyledTableCell align="center" component="th" scope="lot">
            {lot.id}
          </StyledTableCell>
          <StyledTableCell>
            {lot.fruit()?.name}
          </StyledTableCell>
          <StyledTableCell align="right">
            {lot.variety()?.name}
          </StyledTableCell>
          <StyledTableCell align="right">
            {lot.product()?.name}
          </StyledTableCell>
          <StyledTableCell align="right">
            {lot.packagingQuantity}
            {' '}
            [
            {lot.packagingType()?.name}
            ]
          </StyledTableCell>
          <StyledTableCell align="right">
            {roundNumber(lot.quantity)}
            {' '}
            [Kg]
          </StyledTableCell>
          {/* <StyledTableCell align="right">
            {'$ '}
            {roundNumber(lot.price) * roundNumber(lot.quantity)}
            {' '}
          </StyledTableCell> */}
          <StyledTableCell align="right">
            {!isSmallScreen &&
            (<IconButtonWithLoader
              // @ts-ignore
              buttonId="print"
              onClick={printFnc(lot.id)}
              size="small"
              className="hiddem"

            >
              <PrintIcon fontSize="small" />
            </IconButtonWithLoader>)
            }
            {!isSmallScreen && (
                <Tooltip title="Impresión por cantidad de envases">
                  <IconButton
                    // onClick={() => printFncPaks(lot.id, lot.packagingQuantity)}
                    data-id="print-paks"
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                )
              }
            {
              falsy(process.closedAt) && (
                <IconButton
                  onClick={toggleDelete}
                  data-id={lot.id}
                  data-module={falsy(isDepartures) ? 'deleteEntry' : 'deleteLot'}
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )
            }
          </StyledTableCell>
        </TableRow>
      ));
    }

    return rows;
  };

  return (
    <>
      <Grid container spacing={3}>
        {
          lotId ? (
            <Headers
              classes={classes}
              process={process}
              multipleLotsProcess={multipleLotsProcess}
              uniqueLot={uniqueLot}
              toggleClose={toggleClose}
              isAdmin={isAdmin}
              changeControls={changeControls}
              controls={formControls.headers}
              products={products}
              processEquipments={processEquipments}
              equipments={equipments}
              toggleDelete={toggleDelete}
              onBackAction={onBackAction}
            />
          ) : (
            <Grid item container justifyContent="center" alignContent="center">
              <CircularProgress />
            </Grid>
          )
        }
        {lotId && (
          <>
            <Grid item xs={12} md={7}>
              <Entries
                isGroupedInfo={formControls.headers.isGroupedInfo}
                classes={classes}
                process={process}
                lots={objects.lots}
                toggleDelete={toggleDelete}
                entryLots={entryLots}
                printAllFnc={printAllFnc}
                getProductsRows={getProductsRows}
                getProductsHeaders={getProductsHeaders}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <WorkShiftStaff
                classes={classes}
                processStaffLines={processStaffLines}
                process={process}
                toggleDelete={toggleDelete}
                processResources={processResources}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Departures
                isGroupedInfo={formControls.isGroupedInfo}
                classes={classes}
                process={process}
                lots={objects.lots}
                toggleDelete={toggleDelete}
                printFnc={printFnc}
                // printFncPaks={printFncPaks}
                // printPakLabels={printPakLabels}
                printAllFnc={printAllFnc}
                getProductsRows={getProductsRows}
                getProductsHeaders={getProductsHeaders}
                departureLots={departureLots}
                isSmallScreen={isSmallScreen}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <SupplyProductLines
                classes={classes}
                supplyLines={objects.supplyLines}
                process={process}
                toggleDelete={toggleDelete}
              />
            </Grid>
          </>
        )}
      </Grid>
      <StaffForm
        employees={objects.employees}
        controls={formControls.staff}
        invalidControls={invalidControls.staff}
        isOpen={formIsOpen.staff}
        changeControls={changeControls}
        submit={submitStaff}
        toggleForm={toggleForm}
        employeeOptions={employeeOptions}
        processResources={processResources}
      />
      <ProcessEquipmentForm
        employees={objects.employees}
        controls={formControls.processEquipments}
        invalidControls={invalidControls.processEquipments}
        isOpen={formIsOpen.processEquipments}
        changeControls={changeControls}
        submit={submitProcessEquipments}
        toggleForm={toggleForm}
        equipmentOptions={equipmentOptions}
        processResources={processResources}
      />
      <LotForm
        controls={formControls.departure}
        invalidControls={invalidControls.departure}
        isOpen={formIsOpen.departure}
        warehouses={objects.warehouses}
        products={objects.products}
        fruits={objects.fruits}
        varieties={objects.varieties}
        packagingTypes={objects.packagingTypes}
        changeControls={changeControls}
        submit={submitLot}
        toggleForm={toggleForm}
        changeWeight={changeWeight}
        totalWeight={totalWeight}
        entryLots={entryLots}
        process={process}
      />
      {formIsOpen.entry && (
        <EntryForm
          process={process}
          controls={formControls.entry}
          lots={objects.lots}
          invalidControls={invalidControls.entry}
          isOpen={formIsOpen.entry}
          changeControls={changeControls}
          submit={submitEntry}
          toggleForm={toggleForm}
          qrReader={qrReader}
          onChangeFnc={onChangeFnc}
          qrCode={qrCode}
          uniqueLot={uniqueLot}
          getProductsRows={getProductsRows}
          getProductsHeaders={getProductsHeaders}
          isGroupedInfo={formControls.headers.isGroupedInfo}
          classes={classes}
          toggleDelete={toggleDelete}
          entryLots={entryLots}
          printAllFnc={printAllFnc}
          formControls={formControls}
          objects={objects}
          infoEntryLots={infoEntryLots}
          lot={infoLot}
          usedLot={usedLot}
          actions={actions}
          stopLoading={stopLoading}
        />
      )}
      <SupplyProductForm
        controls={formControls.supplyProducts}
        invalidControls={invalidControls.supplyProducts}
        isOpen={formIsOpen.supplyProducts}
        changeControls={changeControls}
        submit={submitSupplyProduct}
        toggleForm={toggleForm}
        supplyProducts={objects.supplyProducts}
        warehouses={objects.warehouses}
        purchases={objects.purchases}
        role={role}
      />
      <DeleteModal
        // eslint-disable-next-line react/destructuring-assignment
        isOpen={props.delete.isOpen}
        toggleForm={toggleDelete}
        submit={submitDelete}
        buttonId="delete-process-item"
      />
      {lotId && falsy(process.closedAt) && (
        <DialButton
          speedDialToggle={speedDialToggle}
          open={dialButtonOpen}
          classes={classes}
          toggleForm={toggleForm}
          disabledDepartures={entryLots.length === 0}
        />
      )}
      <ConfirmDialog
        title={`${truthty(process.closedAt) ? 'Abrir proceso' : 'Finalizar proceso'} Nº ${process.id}`}
        open={formControls.closeModal.isOpen}
        maxWidth="sm"
        message={truthty(process.closedAt) ? `¿Estás seguro de abrir el proceso Nº ${process.id}?` : 'Seleccione una fecha para finalizar el proceso'}
        accept={() => {
          const date = truthty(process.closedAt) ? null : formControls.closeModal.closedAt;
          closeProcess(process.id, date);
        }}
        cancel={toggleClose}
        alert={warningMsg()}
        info={truthty(process.closedAt) ? infoMsg['open'] : infoMsg['close']}
      >
        {
          falsy(process.closedAt) && (
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <DatePicker
                  inputId="closedAt"
                  label="Fecha y hora de cierre"
                  fullWidth
                  value={formControls.closeModal.closedAt}
                  onChange={changeControls}
                  inputProps={{ 'data-module': 'closeModal' }}
                  type="datetime-local"
                />
              </Grid>
            </Grid>
          )
        }
      </ConfirmDialog>
    </>
  );
};

export default ProcessComponent;

/* eslint-disable no-unused-vars */

// import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import { takeLatest, spawn, put, call, take, delay } from 'redux-saga/effects';

import { truthty } from 'utils/functions';
import { setBaudrate, vendorRead, vendorWrite } from 'utils/services/scaleFnc';
import apiRequest, { apiSuccess, objectToQueryString } from '../../utils/api';

import {
  LOG_OUT,
  SEARCH_PRINTER,
  SEARCH_PRINTER_FAIL,
  SEARCH_PRINTER_SUCCESS,
  PRINT_LABEL,
  PRINT_RECEPTION_LABEL,
  PRINT_LABEL_PAKS,
  appActions,
  ACTIVATE,
  SEARCH_SCALE,
  SEARCH_SCALE_FAIL,
  SEARCH_SCALE_SUCCESS,
  READ_SCALE,
  READ_SCALE_SUCCESS,
  READ_SCALE_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_STATE_FROM_API,
  GET_STATE_FROM_API_SUCCESS,
  EXPORT_EXCEL,
  EXPORT_EXCEL_SUCCESS,
  CAPTURE_WEIGHT,
  EDIT_WEIGHT_SCALE,

} from '../reducer/index';

import {
  CHANGE_CONTROLS,
  
} from '../../screens/Receipts/reducer/index';

const PRINTER_VENDOR_ID = 2655;
const WEIGHT_SCALE_VENDOR_ID = 1659;
const vendprId2 = 0x067b

function connect() {
  console.log({ saga: true }, '===> Try connect');
  // TCT: TODO Debug why websockets transport is not working
  // const socket = io();
  // return new Promise((resolve) => {
  //   socket.on('connect', () => {
  //     resolve(socket);
  //   });
  // });
}

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('objects', (e) => {
      if (truthty(e.objects)) {
        emit(appActions.objectsFromSocketReceived({ objects: e.objects }));
      }
      if (truthty(e.ids)) {
        emit(appActions.deleteObjectsFromSocketReceived({ response: { ids: e.ids } }));
      }
    });
    return () => {};
  });
}

function* read(socket) {
  let action;
  const channel = yield call(subscribe, socket);
  while (true) {
    action = yield take(channel);
    yield put(action);
  }
}

function* flow() {
  if (process.env.NODE_ENV === 'production') {
    while (true) {
      yield take(ACTIVATE);
      // TCT: TODO: Connect at loguin
      const socket = yield call(connect);
      window.localStorage.setItem('socketId', socket.id);
      yield put(appActions.connectSocketSuccess(socket));
      // socket.emit('login', { username: payload.username });

      yield spawn(read, socket);

      // let action = yield take(`4321`);
      // yield cancel(task);
      // socket.emit('logout');
    }
  }
}

function* logout() {
  yield takeLatest(LOG_OUT, function* () {
    yield apiRequest('/logout', { method: 'get' });
  });
}

function* searchPrinter() {
  yield takeLatest(SEARCH_PRINTER, function* () {
    // Check if we have devices available
    const devices = yield window.navigator.usb.getDevices();
    let device;
    if (devices.length === 0 || !devices.find((d) => d.vendorId === PRINTER_VENDOR_ID)) {
      try {
        // Get permission from the user to use their printer
        device = yield window.navigator.usb.requestDevice({
          filters: [{ vendorId: PRINTER_VENDOR_ID }],
        });
      } catch (e) {
        yield put({
          type: SEARCH_PRINTER_FAIL,
          msg: 'Dar permisos para utilizar la impresora USB',
        });
      }
    } else {
      device = devices.find((d) => d.vendorId === PRINTER_VENDOR_ID);
    }

    if (device) {
      try {
        yield device.open();
        yield device.selectConfiguration(1);
        yield device.claimInterface(0);

        yield put({
          type: SEARCH_PRINTER_SUCCESS,
          device,
        });
      } catch (e) {
        yield put({
          type: SEARCH_PRINTER_FAIL,
          msg: 'La impresora está siendo utilizada',
        });
      }
    } else {
      yield put({
        type: SEARCH_PRINTER_FAIL,
        msg: 'No se encontraron impresoras conectadas',
      });
    }
  });
}

function* printLabel() {
  yield takeLatest(PRINT_LABEL, function* (action) {
    if (action.device.opened) {
      const response = yield apiRequest(`api/v1/lots/generateLabels`, {
        method: 'post',
        body: JSON.stringify(action.labelData),
      });
      if (response.error) {
        yield put(appActions.setError('Error al generar las etiquetas'));
      } else {
        yield put(appActions.setSuccess('Etiquetas Generadas'));
        const encoder = new TextEncoder();
        const labelsToPrint = response.printLabels.join('');
        const data = encoder.encode(labelsToPrint);
        console.log('data', data)
        try {
          yield put(appActions.setLoader('printing', true));
          const res = yield action.device.transferOut(1, data);
          yield put(appActions.setLoader('printing', false));
          if (res.status === 'ok') {
            yield put(appActions.setSuccess('Impresión completada'));
          }
        } catch (e) {
          yield put(appActions.setError('Impresora desconectada'));
        }
      }
    } else {
      yield put(appActions.setError('Impresora no conectada'));
    }
  });
}

function* printReceptionLabel() {
  yield takeLatest(PRINT_RECEPTION_LABEL, function* (action) {
    if (action.device.opened) {
      const response = yield apiRequest(`api/v1/lots/generateReceiptLabel`, {
        method: 'post',
        body: JSON.stringify(action.labelData),
      });
      if (response.error) {
        yield put(appActions.setError('Error al generar las etiquetas'));
      } else {
        yield put(appActions.setSuccess('Etiquetas Generadas'));
        const encoder = new TextEncoder();
        const data = encoder.encode(response.lotLabels);
        try {
          yield put(appActions.setLoader('printing', true));
          const res = yield action.device.transferOut(1, data);
          yield put(appActions.setLoader('printing', false));
          if (res.status === 'ok') {
            yield put(appActions.setSuccess('Impresión completada'));
          }
        } catch (e) {
          yield put(appActions.setError('Impresora desconectada'));
        }
      }
    } else {
      yield put(appActions.setError('Impresora no conectada'));
    }
  });
}

function* printLabelPaks() {
  yield takeLatest(PRINT_LABEL_PAKS, function* (action) {
    if (action.device.opened) {
      yield put(appActions.setSuccess('Etiquetas Generadas'));
      const encoder = new TextEncoder();
      const data = encoder.encode(action.labelData.lotIds);
      console.log('data', data)
      try {
        yield put(appActions.setLoader('printing', true));
        const res = yield action.device.transferOut(1, data);
        yield put(appActions.setLoader('printing', false));
        if (res.status === 'ok') {
          yield put(appActions.setSuccess('Impresión completada'));
        }
      } catch (e) {
        yield put(appActions.setError('Impresora desconectada'));
      }
    } else {
      yield put(appActions.setError('Impresora no conectada'));
    }
  });
}

function* searchWeightScale() {
  yield takeLatest(SEARCH_SCALE, function* () {
    // Check if we have devices available
    const devices = yield window.navigator.usb.getDevices();

    let device;

    if (devices.length === 0 || !devices.find((d) => d.vendorId === WEIGHT_SCALE_VENDOR_ID || d.vendorId === vendprId2)) {
      try {
        // Get permission from the user to use their printer
        device = yield window.navigator.usb.requestDevice({
          filters: [{ vendorId: WEIGHT_SCALE_VENDOR_ID }, { vendorId: vendprId2 }],
        });
      } catch (e) {
        yield put({
          type: SEARCH_SCALE_FAIL,
          msg: 'Dar permisos para utilizar la pesa',
        });
      }
    } else {
      device = devices.find((d) => d.vendorId === WEIGHT_SCALE_VENDOR_ID || d.vendorId === vendprId2);
    }

    if (device) {
      try {
        yield put({
          type: SEARCH_SCALE_SUCCESS,
          device,
        });
      } catch (e) {
        yield put({
          type: SEARCH_SCALE_FAIL,
          msg: 'La pesa está siendo utilizada',
        });
      }
    } else {
      yield put({
        type: SEARCH_SCALE_FAIL,
        msg: 'No se encontraron pesas conectadas',
      });
    }
  });
}

function* readScale() {
  yield takeLatest(SEARCH_SCALE_SUCCESS, function* (action) {
  // yield takeLatest(READ_SCALE, function* (action) {

    const { device } = action;
    if (truthty(device.productId)) {
      let reading = true;
      window.navigator.usb.addEventListener('disconnect', (event) => {
        device.close();
        reading = false;
      });

      yield device.open();
      yield device.selectConfiguration(1);
      
      try {
        yield device.claimInterface(device.configuration.interfaces[0].interfaceNumber);
        yield vendorRead(device, 0x8484, 0);
        yield vendorWrite(device, 0x0404, 0);
        yield vendorRead(device, 0x8484, 0);
        yield vendorRead(device, 0x8383, 0);
        yield vendorRead(device, 0x8484, 0);
        yield vendorWrite(device, 0x0404, 1);
        yield vendorRead(device, 0x8484, 0);
        yield vendorRead(device, 0x8383, 0);
        yield vendorWrite(device, 0, 1);
        yield vendorWrite(device, 1, 0);
        yield vendorWrite(device, 2, 0x44);
        yield setBaudrate(device);
      } catch (error) {
        console.log('error => ', error)
      }
      

      let weight = 0;
      while (reading) {
        yield delay(100);
        let decoder;
        try {
          const newWeight = yield device
          .transferIn(3, 2048)
          .then((result) => {
            decoder = new TextDecoder();
            const decodedResult = decoder.decode(result.data);
            const w = parseFloat(decodedResult.match(/.......kg/)[0].split('kg')[0]);
            return w;
          })
          .catch((error) => {
            console.log('error', error)
            return weight
          });
        yield put({
          type: CAPTURE_WEIGHT,
          scaleWeight: newWeight,
        });
        } catch (e) {
          console.error('error', e)
        }
      }
      yield put({
        type: READ_SCALE_SUCCESS,
      });
      device.close();
    } else {
      yield put({
        type: READ_SCALE_FAIL,
        msg: 'No se ha detectado pesa',
      });
    }
  });
}

function* editWeightScale() {
  yield takeLatest(EDIT_WEIGHT_SCALE, function* (action) {
    const { scaleWeight, fnc } = action;
    fnc(scaleWeight);
  });
}

function* getUser() {
  yield takeLatest(GET_USER, function* () {
    const response = yield apiRequest('api/v1/users/user', { method: 'get' }, true);
    if (truthty(response)) {
      yield put(apiSuccess(GET_USER_SUCCESS, { user: response.User, role: response.Role }));
    } else {
      yield put(apiSuccess(GET_USER_FAIL));
    }
  });
}

function* getStateFromApi() {
  yield takeLatest(GET_STATE_FROM_API, function* () {
    const response = yield apiRequest('api/v1/app', { method: 'get' }, true);
    if (truthty(response)) {
      yield put(apiSuccess(GET_STATE_FROM_API_SUCCESS, response));
    }
  });
}

function* exportExcel() {
  yield takeLatest(EXPORT_EXCEL, function* (action) {
    yield put(appActions.setLoader('export-dispatches', true));
    const query = objectToQueryString({...action.filters, socketId: action.socketId});
    const response = yield apiRequest(
      `api/v1/${action.path}?${query}`,
      { method: 'get' },
      false,
      // `${action.fileName || 'exportar'}.xlsx`,
    );
    yield put(apiSuccess(EXPORT_EXCEL_SUCCESS, response));
    yield put(appActions.setLoader('export-dispatches', false));
  });
}

export default function* root() {
  yield spawn(searchWeightScale);
  yield spawn(searchPrinter);
  yield spawn(readScale);
  yield spawn(getUser);
  yield spawn(logout);
  yield spawn(printLabel);
  yield spawn(printReceptionLabel);
  yield spawn(printLabelPaks);
  yield spawn(flow);
  yield spawn(getStateFromApi);
  yield spawn(exportExcel);
  yield spawn(editWeightScale);
}